
import CloudFun, { defineComponent, ref } from "@cloudfun/core";
import Grid, { GridOptions } from "@/cloudfun/components/Grid.vue";
import { VxeFormProps } from "vxe-table";

export default defineComponent({
  components: {
    Grid,
  },
  setup() {
    const model = CloudFun.current?.model;
    const grid = ref<any>({});

    var printColumns = [
      { field: "Number" },
      { field: "Name" },
      { field: "Address.Line" },
    ];

    const gridOptions: GridOptions = {
      id: "warehouse",
      title: "倉庫",
      multiselect: false,
      toolbarConfig: { custom: true, refresh: true },
      printConfig: { sheetName: "倉庫清單", columns: printColumns, modes: ["current", "selected", "all"] },
      exportConfig: { filename: "倉庫清單", type: "csv", types: ["html", "csv"], mode: "all", modes: ["current", "selected", "all"], columns: printColumns },
      columns: [
        { field: 'Number', title: '編號', showHeaderOverflow: true, showOverflow: true, width: 130, sortable: true },
        { field: "Name", title: "名稱", showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: 'Address.Line', title: '地址', showHeaderOverflow: true, showOverflow: true, sortable: true, resizable: false }
      ],
      promises: {
        query: model ? (params) => model.dispatch("warehouse/query", params) : undefined,
        queryAll: model ? () => model.dispatch("warehouse/query") : undefined,
        save: model ? (params) => model.dispatch("warehouse/save", params) : undefined,
      },
      modalConfig: { width: 600, showFooter: true },
    };

    const formOptions: VxeFormProps = {
      titleWidth: 80,
      titleAlign: "right",
      span: 3,
      items: [
        { field: 'Number', title: '編號', span: 12, itemRender: { name: '$input', props: { placeholder: '系統自動產生', disabled: true } } },
        { field: "Name", title: "名稱", span: 12, itemRender: { name: "$input", props: { placeholder: "請輸入名稱" } } },
        { field: "Address.Line", title: "地址", span: 24, itemRender: { name: '$input', props: { placeholder: '請輸入地址', clearable: true }, attrs: { type: 'text' } } },
        { field: "Remark", title: "備註", span: 24, itemRender: { name: '$textarea', props: { placeholder: '請輸入備註', clearable: true }, attrs: { type: 'text' } } },
      ],
      rules: {
        Name: [{ required: true }],
      }
    };

    return {
      grid,
      gridOptions,
      formOptions,
    };
  },
  methods: {
    hideBatchDropDown () {
      cash("#batch-dropdown").dropdown("hide");
    }
  }
});
